<template>
    <v-card>
        <v-card-title>
            <span class="headline">Propiedades</span>
            <div class="flex-grow-1"></div>
            <v-btn color="black" text icon @click="onCerrarPropiedades()">
                <v-icon>mdi-close</v-icon>
            </v-btn>
        </v-card-title>
        <v-card-text>
            <v-container v-if="datos">
                <v-row>
                    <v-col cols="12" sm="12" md="12">
                        <v-row no-gutters>
                            <v-col cols="12" sm="12" md="12">
                                <v-text-field outlined dense label="Codigo" v-model="datos.ecseCodigo" :error="validaciones.ecseCodigo.$error"></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="12" md="12">
                                <v-text-field outlined dense label="Nombre" v-model="datos.ecseNombre" :error="validaciones.ecseNombre.$error"></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="12" md="12" v-if="datos.tipo == 'CAMPO'">
                                <v-autocomplete outlined dense v-model="datos.ecseTipoTipodato" :items="datos.tipodatos" item-text="comaNombrelargo" item-value="comaId" item-color="primary" label="Tipo dato" @change="cambiarTipo" clearable>
                                </v-autocomplete>
                            </v-col>
                            <v-col cols="12" sm="6" md="6">
                                <v-text-field outlined dense label="Orden" v-model="datos.ecseOrden" number></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="6" md="6">
                                <v-text-field outlined dense label="Num. de columnas(1-12)" v-model="datos.ecseCol" number></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="4" md="4">
                                <v-text-field outlined dense label="Numero filas TEXTAREA" v-model="datos.ecseFilas" number></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="4" md="4">
                                <v-autocomplete outlined dense v-model="datos.ecseSexo" :items="[{texto:'Hombre',valor:1},{texto:'Mujer',valor:2},{texto:'Ambos',valor:0}]" item-text="texto" item-value="valor" item-color="primary" label="Sexo" clearable>
                                </v-autocomplete>
                            </v-col>
                            <v-col cols="12" sm="4" md="4">
                                <v-text-field outlined dense label="Version" v-model="datos.ecseVersion"></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="6" md="6">
                                <v-text-field outlined dense label="Edad inicial" v-model="datos.ecseEdadinicio"></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="6" md="6">
                                <v-text-field outlined dense label="Edad final" v-model="datos.ecseEdadfinal"></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="6" md="6">
                                <v-switch dense v-model="datos.ecseRequerido" label="Requerido"></v-switch>
                            </v-col>
                            <v-col cols="12" sm="6" md="6">
                                <v-switch dense v-model="datos.ecseEstado" label="Activo"></v-switch>
                            </v-col>
                            <v-col cols="12" sm="6" md="6" v-if="datos.tipo == 'SECCION'">
                                <v-switch dense v-model="datos.ecseTriage" label="Triage"></v-switch>
                            </v-col>
                            <v-col cols="12" sm="12" md="12">
                                <v-text-field outlined dense label="Valores" v-model="datos.ecseValores" @click="cargarValores()"></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="12" md="12">
                                <v-textarea outlined dense v-model="datos.ecseDescripcion" label="Descripcion"></v-textarea>
                            </v-col>
                            <v-col cols="12" sm="12" md="12">
                                <v-textarea outlined dense v-model="datos.ecseDefault" label="Valor por defecto"></v-textarea>
                            </v-col>
                            <v-col cols="12" sm="12" md="12">
                                <v-textarea outlined dense v-model="datos.ecseConsulta" label="Consulta"></v-textarea>
                            </v-col>
                            <v-col cols="12" sm="12" md="6" v-if="datos.tipo == 'SECCION'">
                                <v-btn color="success" @click="modalTipo='servicios';dialog = true">Encuestas</v-btn>
                                <br>
                            </v-col>
                            <v-col cols="12" sm="12" md="6" v-if="datos.tipo == 'SECCION'">
                                <v-btn color="info" @click="modalTipo='script';dialog = true">
                                    SCRIPTS
                                </v-btn>
                                <br>
                            </v-col>
                            <v-col cols="12" sm="6" md="6" v-if="datos.tipo == 'SECCION'">
                                <v-switch dense v-model="datos.ecseHistorico" label="Historico"></v-switch>
                            </v-col>
                            <v-col cols="12" sm="6" md="6" v-if="datos.tipo == 'SECCION'">
                                <v-switch dense v-model="datos.ecseCalculado" label="Calculado"></v-switch>
                            </v-col>

                            <v-col cols="12" sm="6" md="6">
                                <v-btn color="error" @click="onEliminarCampo(datos)">
                                    Eliminar<v-icon>delete</v-icon>
                                </v-btn>
                            </v-col>
                            <v-col cols="12" sm="6" md="6">
                                <v-btn color="primary" @click="onGuardar(datos)">
                                    Guardar<v-icon>create</v-icon>
                                </v-btn>
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>
                <v-dialog v-model="dialog" persistent width="1200px" max-width="1200px">
                    <v-card v-if="modalTipo=='script'">
                        <v-card-title>
                            <span class="headline">SCRIPT</span>
                        </v-card-title>
                        <v-divider></v-divider>
                        <v-card-text>
                            <v-container>
                                <v-row>
                                    <v-col cols="12" sm="12" md="12" v-if="cmOptions != null">
                                        <codemirror ref="myCm" :value="datos.ecseScript" :options="cmOptions" @input="onCmCodeChange" style="height:800px">
                                        </codemirror>
                                    </v-col>
                                </v-row>
                            </v-container>
                        </v-card-text>
                        <v-divider></v-divider>
                        <v-card-actions>
                            <div class="flex-grow-1"></div>
                            <v-btn color="primary darken-1" text @click="dialog = false;script = datos.ecseScript">Cancelar</v-btn>
                            <v-btn color="primary darken-1" dark @click="datos.ecseScript = script;dialog = false">Guardar</v-btn>
                        </v-card-actions>
                    </v-card>
                    <v-card v-if="modalTipo=='valores'">
                        <v-card-title>
                            <span class="headline">Valores</span>
                        </v-card-title>
                        <v-divider></v-divider>
                        <v-card-text>
                            <v-container>
                                <v-row>
                                    <template v-for="(valor,index) in valores">
                                        <v-col cols="12" sm="12" md="4" :key="'TEXTO_'+index">
                                            <v-text-field dense outlined v-model="valor.texto"></v-text-field>
                                        </v-col>
                                        <v-col cols="12" sm="12" md="4" :key="'VALOR_'+index">
                                            <v-text-field dense outlined v-model="valor.valor"></v-text-field>
                                        </v-col>
                                        <v-col cols="auto" sm="12" md="4" :key="'OPCIONES_'+index">
                                            <v-btn text icon color="success" @click="agregarValor()">
                                                <v-icon>mdi-plus</v-icon>
                                            </v-btn>
                                            <v-btn text icon color="error" v-if="index > 0" @click="eliminarValor(valor)">
                                                <v-icon>delete</v-icon>
                                            </v-btn>
                                        </v-col>
                                    </template>
                                </v-row>
                            </v-container>
                        </v-card-text>
                        <v-divider></v-divider>
                        <v-card-actions>
                            <div class="flex-grow-1"></div>
                            <v-btn color="primary darken-1" text @click="dialog = false;valoresAnterior = datos.ecseValores">Cancelar</v-btn>
                            <v-btn color="primary darken-1" dark @click="guardarValores()">Guardar</v-btn>
                        </v-card-actions>
                    </v-card>
                    <v-card v-if="modalTipo=='servicios'">
                        <v-card-title>
                            <span class="headline">Encuestas</span>
                        </v-card-title>
                        <v-divider></v-divider>
                        <v-card-text>
                            <v-container>
                                <v-row>
                                    <template v-for="(encuesta,index) in serviciosseccion">
                                        <v-col cols="12" sm="12" md="8" :key="'SERVICIO_'+index">
                                            <v-autocomplete outlined dense v-model="encuesta.coenId" :items="servicios" item-text="coenNombre" item-value="coenId" item-color="primary" label="Encuesta"></v-autocomplete>
                                        </v-col>
                                        <v-col cols="auto" sm="12" md="4" :key="'SERVICIOSOPCIONES_'+index">
                                            <v-btn text icon color="success" @click="agregartblConfHistoriasservicios()">
                                                <v-icon>mdi-plus</v-icon>
                                            </v-btn>
                                            <v-btn text icon color="error" v-if="index > 0" @click="eliminartblConfHistoriasservicios(encuesta)">
                                                <v-icon>delete</v-icon>
                                            </v-btn>
                                        </v-col>
                                    </template>
                                </v-row>
                            </v-container>
                        </v-card-text>
                        <v-divider></v-divider>
                        <v-card-actions>
                            <div class="flex-grow-1"></div>
                            <v-btn color="primary darken-1" text @click="dialog = false;serviciosseccion = datos.tblConfHistoriasservicios">Cancelar</v-btn>
                            <v-btn color="primary darken-1" dark @click="guardarServicios()">Guardar</v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>
            </v-container>
        </v-card-text>
    </v-card>
</template>

<script>
import "codemirror/mode/javascript/javascript.js";
import "codemirror/theme/base16-dark.css";
// active-line.js
import "codemirror/addon/selection/active-line.js";
// styleSelectedText
import "codemirror/addon/selection/mark-selection.js";
import "codemirror/addon/search/searchcursor.js";
// highlightSelectionMatches
import "codemirror/addon/scroll/annotatescrollbar.js";
import "codemirror/addon/search/matchesonscrollbar.js";
import "codemirror/addon/search/searchcursor.js";
import "codemirror/addon/search/match-highlighter.js";
// keyMap
import "codemirror/mode/clike/clike.js";
import "codemirror/addon/edit/matchbrackets.js";
import "codemirror/addon/comment/comment.js";
import "codemirror/addon/dialog/dialog.js";
import "codemirror/addon/dialog/dialog.css";
import "codemirror/addon/search/searchcursor.js";
import "codemirror/addon/search/search.js";
import "codemirror/keymap/sublime.js";

export default {
  name: 'Dfiltros',
  props: {
    onEliminarCampo: Function,
    onGuardar: Function,
    datos: Object,
    onCerrarPropiedades: Function,
    validaciones: Object
  },
  data() {
    return {
      dialog: false,
      script: null,
      valores: [],
      modalTipo: null,
      valoresAnterior: null,
      servicios: [],
      serviciosseccion: [],
      cmOptions: typeof window !== "undefined" ? {
        tabSize: 4,
        styleActiveLine: true,
        lineNumbers: true,
        line: true,
        //styleSelectedText: true,
        mode: "text/javascript",
        keyMap: "sublime",
        showCursorWhenSelecting: true,
        theme: "base16-dark",
        extraKeys: {
          Ctrl: "autocomplete"
        },
        hintOptions: {
          completeSingle: true
        }
      } : null,
    }
  },
  created: async function() {
    this.servicios = await this.$apiService.index("sistema/encuestas/index/").then(data => data);
  },
  updated() {
    if (this.datos) {
      this.script = this.datos.ecseScript ? this.datos.ecseScript : '';
      this.valoresAnterior = this.datos.ecseValores
      this.serviciosseccion = this.datos.encuestas
    }
  },
  methods: {
    onCmCodeChange(newCode) {
      this.datos.ecseScript = newCode;
    },
    cambiarTipo() {
      if (this.datos.ecseTipoTipodato) {
        this.datos.tipodato = this.datos.tipodatos.find(e => e.comaId == this.datos.ecseTipoTipodato);
      }
    },
    cargarValores() {
        this.modalTipo='valores';
        this.dialog = true;
      if (this.datos.ecseValores != null && this.datos.ecseValores.trim() != '') {
        this.valores = [];
        let datos = this.datos.ecseValores.split('|');
        for (let i = 0; i < datos.length; i++) {
          let d = datos[i];
          this.valores.push({
            texto: d.split(';')[0],
            valor: d.split(';')[1]
          });
        }
      } else {
        this.valores.push({
          texto: null,
          valor: null
        })
      }
    },
    agregarValor() {
      this.valores.push({
        texto: null,
        valor: null
      })
    },
    eliminarValor(valor) {
      this.valores = this.valores.filter(e => e != valor)
    },
    guardarValores() {
      let datosValores = this.valores.filter(e => e.texto && e.valor)
      if (datosValores.length > 0) {
        let valores = '';
        datosValores.forEach((item, index) => {
          valores = `${valores}${index>0?'|':''}${item.texto};${item.valor}`
        });
        this.datos.ecseValores = valores;
        this.valoresAnterior = this.datos.ecseValores
      } else {
        this.datos.ecseValores = null;
      }
      this.dialog = false;
    },
    agregartblConfHistoriasservicios() {
      if (this.datos.ecseId) {
        this.serviciosseccion.push({
          ecseId: this.datos.ecseId,
          coenId: null
        });
      } else {
        this.serviciosseccion.push({
          coenId: null
        });
      }
    },
    eliminartblConfHistoriasservicios(item) {
      this.serviciosseccion = this.serviciosseccion.filter(e => e != item);
    },
    guardarServicios() {
      this.datos.encuestas = this.serviciosseccion;
      this.dialog = false;
    }
  },
}

</script>

<style lang="css">
.CodeMirror {
  min-height: 800px;
}

</style>
